import useAuth from "@/hooks/useAuth";
import useMediaQuery from "@/hooks/useMediaQuery";

function FooterPage() {
  const isMd = useMediaQuery();
  const { isReady } = useAuth();

  if (!isReady || location.pathname.startsWith("/tl/")) return <></>;

  return (
    <div className='relative border-t-4 border-blue-900 bg-white font-sans sm:py-8 md:h-[400px] md:p-8 md:px-16'>
      <div className='text-[14px] md:flex'>
        <div className='sm:flex sm:w-full sm:justify-center md:w-1/3'>
          <img src='/images/logo.svg ' alt='logo'></img>
        </div>
        {!isMd ? (
          <div className='flex w-full justify-end  font-bold'>
            <div className='ml-5'>
              <a className='hover:text-gray-500' href='https://recruitment.united.jp/' target='_blank' rel='noopener noreferrer'>
                会社概要
              </a>
            </div>
            <div className='ml-5'>
              <a className='hover:text-gray-500' href='/term' target='_blank' rel='noopener noreferrer'>
                利用規約
              </a>
            </div>
            <div className='ml-5'>
              <a className='hover:text-gray-500' href='https://termhub.jp/view/term.html?k=O0EXm%2FMIpuQdH1LDZN1AxRVQAiIss7O1cgis7IVOfDc%3D' target='_blank' rel='noopener noreferrer'>
                プライバシーポリシー
              </a>
            </div>
            <div className='ml-5'>
              <a
                className='hover:text-gray-500'
                href='https://job.kasooku.jp/inq'
                target='_blank'
                rel='noopener noreferrer'
              >
                お問い合わせ
              </a>
            </div>
            <div className='ml-5'>
              <a className='hover:text-gray-500' href='https://termhub.jp/view/term.html?k=O0EXm%2FMIpuQdH1LDZN1AxVtmoYKakDNmF45fRzXQib0%3D' target='_blank' rel='noopener noreferrer'>
                取り扱い職種の範囲等の明示
              </a>
            </div>
          </div>
        ) : (
          <div className='mt-8 w-full justify-end text-[13px]  font-bold'>
            <div className='flex w-full border-y border-gray-300'>
              <div className='w-1/2 py-2.5 text-center'>
                <a className='' href='/'>
                  ホーム
                </a>
              </div>
              <div className='w-1/2 border-l border-gray-300 py-2.5 text-center'>
                <a className='' href='https://recruitment.united.jp/' target='_blank' rel='noopener noreferrer'>
                  会社概要
                </a>
              </div>
            </div>
            <div className='flex w-full '>
              <div className='w-1/2 py-2.5 text-center'>
                <a className='' href='/term' target='_blank' rel='noopener noreferrer'>
                  利用規約
                </a>
              </div>
              <div className='w-1/2 border-l border-gray-300 py-2.5 text-center'>
                <a className='' href='https://termhub.jp/view/term.html?k=O0EXm%2FMIpuQdH1LDZN1AxRVQAiIss7O1cgis7IVOfDc%3D' target='_blank' rel='noopener noreferrer'>
                  プライバシーポリシー
                </a>
              </div>
            </div>
            <div className='flex w-full border-gray-300'>
              <div className='w-1/2 border-b border-gray-300 py-2.5 text-center'>
                <a className='' href='https://job.kasooku.jp/inq' target='_blank' rel='noopener noreferrer'>
                  お問い合わせ
                </a>
              </div>
              <div className='w-1/2 border-b border-l border-gray-300 py-2.5 text-center'>
                <a href='https://termhub.jp/view/term.html?k=O0EXm%2FMIpuQdH1LDZN1AxVtmoYKakDNmF45fRzXQib0%3D' target='_blank' rel='noopener noreferrer'>
                  取り扱い職種の範囲等の明示
                </a>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className='mt-4A flex w-full  justify-end pb-4 text-[10px] italic sm:pr-8 md:pr-4'>
        <p>©UNITED Recruitment Inc. All rights reserved.</p>
      </div>
    </div>
  );
}

export default FooterPage;
