const LoginModal = (props: {
  isLoginModal: boolean | null;
  setIsLoginModal: React.Dispatch<React.SetStateAction<boolean | null>>;
  setIsOpenLoginMenue: (value: React.SetStateAction<boolean>) => void;
}) => {
  const triangleStyle = {
    width: "10px",
    height: "10px",
    borderLeft: "10px solid transparent",
    borderRight: "10px solid transparent",
    borderBottom: "10px solid #3c447c",
  };
  return (
    <div className='p-2 text-center text-[12px] font-semibold text-[#3c447c]    '>
      <a href='/register/legal'>
        <div className='mx-1 my-1 flex items-center justify-center border-b border-[#3c447c] py-2'>
          新規会員登録(無料)
        </div>
      </a>
      <a href='/login'>
        <div className='mx-1 my-1 flex items-center justify-center border-b border-[#3c447c] py-2'>ログイン</div>
      </a>
      <div className='flex items-center justify-center py-2 text-[10px] font-normal text-gray-900'>
        {/* <a href='https://business.kasooku.jp/' className='flex' target='_blank' rel='noopener noreferrer'>
          <p className='mr-1 pt-1 hover:text-gray-600'>採用したい企業様はこちら</p>
          <img src='/images/arrow-top-right.svg' className='mt-[2px] h-4 w-4' alt='arrow' />
        </a> */}
      </div>
      <div className='absolute right-[26px] top-[-10px] w-10' style={triangleStyle}></div>
    </div>
  );
};

export default LoginModal;
