import { useNavigate } from "react-router-dom";

import { useRecoilValue } from "recoil";

import MyProfile from "@/components/layouts/profile/MyProfile";
import { userSelector } from "@/stores/selectors/userSelector";

type CompleteModalProps = {
  setIsCompleteModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const RegistrationCompleteModal = (props: CompleteModalProps) => {
  const navigate = useNavigate();
  const user = useRecoilValue(userSelector);

  const shouldShowCareerConsultingLink = user?.willingness_job_change === 1 ||
    user?.willingness_job_change === 5 ||
    user?.willingness_job_change === 6;

  const closeModal = () => {
    props.setIsCompleteModalOpen(false);
    navigate("/profile");
  };
  return (
    <div>
      <div
        className='fixed left-0 top-0 z-40 block h-full w-full cursor-pointer bg-modal-overlay'
        onClick={closeModal}
      />
      <div className='absolute left-1/2 z-50 h-fit -translate-x-1/2 -translate-y-1/2 transform overflow-x-hidden rounded-md bg-white pb-8 pt-4 sm:top-[480px] sm:w-[300px] sm:px-4 md:top-1/2 md:w-[662px] md:px-8'>
        <div className='relative'>
          <img
            src='/images/x-mark-blue.svg'
            alt=''
            className='absolute right-0 h-7 w-7 cursor-pointer'
            onClick={closeModal}
          ></img>
        </div>
        <h1 className='mb-[18px] mt-[28px] text-center text-[20px] font-bold sm:mx-auto sm:w-[200px] md:w-fit'>
          プロフィールの登録が完了しました！
        </h1>
        <div className='mb-[18px] w-full '>
          <div className='flex justify-center'>
            <div className={`sm:mb-[18px] sm:flex sm:items-center sm:justify-center ${shouldShowCareerConsultingLink ? 'md:mr-[18px]' : ''}`}>
              <MyProfile isCompleteModal={true} setIsEditModalOpen={props.setIsCompleteModalOpen} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegistrationCompleteModal;
