import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { useRecoilState } from "recoil";
import * as wanakana from "wanakana";
import * as yup from "yup";

import Loading from "@/components/layouts/common/Loading";
import ProgressBar from "@/components/layouts/common/ProgressBar";
import useControlBlowserBack from "@/hooks/useControlBlowserBack";
import useToast from "@/hooks/useToast";
import { locationState } from "@/stores/atoms/locationAtom";
import { userSelector } from "@/stores/selectors/userSelector";
import { Identification, User, Register, Query } from "@/types/user";
import { apiPost } from "@/utils/api";

function RegisterPage() {
  useControlBlowserBack();
  const navigate = useNavigate();
  const { error } = useToast();
  const [, setKey] = useRecoilState(locationState);
  const [, setUser] = useRecoilState(userSelector);
  const [queryParams, setQueryParams] = useState({});

  const schema = yup.object({
    first_name: yup.string().required("名は必須入力項目です"),
    last_name: yup.string().required("姓は必須入力項目です"),
    first_name_kana: yup
      .string()
      .required("メイは必須項目です")
      .test("katakana-checker", "カタカナで入力してください", (value: string) => !!value.match(/^[ァ-ヶー]*$/)),
    last_name_kana: yup
      .string()
      .required("セイは必須入力項目です")
      .test("katakana-checker", "カタカナで入力してください", (value: string) => !!value.match(/^[ァ-ヶー]*$/)),
    phone_number: yup
      .string()
      .required("電話番号は必須入力項目です")
      .matches(/^0\d{9,10}$/, "有効な電話番号を入力してください(例：09012345678)"),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<Identification>({
    reValidateMode: "onSubmit",
    resolver: yupResolver(schema),
  });

  const isKanji = (text: string): boolean => {
    return !!text.match(/[\u4E00-\u9FFF]/);
  };

  const handleNameChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    field: "first_name" | "last_name"
  ) => {
    const value = event.target.value;
    const kanaField = `${field}_kana` as "first_name_kana" | "last_name_kana";
    
    if (value && !isKanji(value)) {
      // 漢字以外（ひらがな）の場合のみカタカナに変換
      const kana = wanakana.toKatakana(value);
      setValue(kanaField, kana);
    }
    
    register(field).onChange(event);
  };

  const handleKanaChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    field: "first_name_kana" | "last_name_kana"
  ) => {
    const value = event.target.value;
    if (value) {
      // 入力された文字を強制的にカタカナに変換
      const kana = wanakana.toKatakana(value);
      setValue(field, kana);
    } else {
      setValue(field, "");
    }
  };

  const onSubmit: SubmitHandler<Identification | Register> = async (params) => {
    setKey((prevValue) => ({ ...prevValue, isLoading: true }));
    params = {
      ...params,
      ...queryParams,
    };
    const res = await apiPost<undefined, { phone_number: string[] }>("/api/register", params);
    setKey((prevValue) => ({ ...prevValue, isLoading: false }));
    if (res.code === 200) {
      setUser((prevState: User) => Object.assign({}, prevState, params));
      setKey((prevValue) => ({ ...prevValue, path: "/register/sms" }));
      navigate("/register/sms");
      return;
    }

    if (res.code === 422 && res.errors) {
      const message = res.errors["phone_number"][0];
      switch (message) {
        case "すでに登録されています":
          error(message);
          setKey((prevValue) => ({ ...prevValue, path: "/register/email" }));
          navigate("/register/email");
          break;
        default:
          error(message);
          break;
      }
    }
  };

  useEffect(() => {
    const queryParamsString = localStorage.getItem("query");
    if (!queryParamsString) return;

    const cleanedQueryParamsString = queryParamsString.startsWith("?") ? queryParamsString.slice(1) : queryParamsString;
    const searchParams = new URLSearchParams(cleanedQueryParamsString);

    // クエリパラメータをオブジェクトに変換
    const queryParamsObj: Query = Array.from(searchParams.entries()).reduce((acc, [key, value]) => {
      acc[key as keyof Query] = key === "utm_term" ? encodeURI(value) : value;
      return acc;
    }, {} as Query);

    setQueryParams(queryParamsObj);
  }, []);

  return (
    <div className='flex min-h-screen w-full flex-col bg-blue-100 font-sans sm:min-h-[900px]'>
      <Loading />
      <div className='container mx-auto flex max-w-md flex-1 flex-col items-center justify-center px-2'>
        <div className='text-black absolute flex justify-center rounded bg-white px-6 py-8 sm:top-[88px] sm:h-[800px] sm:w-full md:top-[111px] md:h-[760px] md:w-[760px]'>
          <ProgressBar progress={1} />
          <div className='absolute w-[336px] text-[13px] sm:top-[110px] md:top-[90px]'>
            <h1 className='mb-8 text-center text-[20px] font-bold'>本人確認</h1>
            <h2 className='mb-8 text-center text-[14px]'>SMSを利用した本人確認を行います。</h2>
            <div className='form-group mb-4 flex'>
              <div className='mb-2 mr-1'>
                <label htmlFor='last-name' className='text-[13px] font-bold'>
                  姓
                </label>
                <input
                  type='text'
                  className='border-grey-light block w-full rounded border p-3'
                  {...register("last_name")}
                  onChange={(e) => handleNameChange(e, "last_name")}
                />
                <span className='absolute p-1 text-xs text-red-500'>{errors.last_name?.message}</span>
              </div>
              <div className='ml-1'>
                <label htmlFor='first-name' className='text-[13px] font-bold'>
                  名
                </label>
                <input
                  type='text'
                  className='border-grey-light block w-full rounded border p-3'
                  {...register("first_name")}
                  onChange={(e) => handleNameChange(e, "first_name")}
                />
                <span className='absolute p-1 text-xs text-red-500'>{errors.first_name?.message}</span>
              </div>
            </div>

            <div className='form-group mb-4 flex'>
              <div className='mb-2 mr-1'>
                <label htmlFor='last-name-kana' className='text-[13px] font-bold'>
                  セイ
                </label>
                <input
                  type='text'
                  className='border-grey-light block w-full rounded border p-3'
                  {...register("last_name_kana")}
                  onChange={(e) => handleKanaChange(e, "last_name_kana")}
                />
                <span className='absolute p-1 text-xs text-red-500'>{errors.last_name_kana?.message}</span>
              </div>
              <div className='ml-1'>
                <label htmlFor='first-name-kana' className='text-[13px] font-bold'>
                  メイ
                </label>
                <input
                  type='text'
                  className='border-grey-light block w-full rounded border p-3'
                  {...register("first_name_kana")}
                  onChange={(e) => handleKanaChange(e, "first_name_kana")}
                />
                <span className='absolute p-1 text-xs text-red-500'>{errors.first_name_kana?.message}</span>
              </div>
            </div>

            <div className='form-group mb-6'>
              <div className='mb-2 mr-1'>
                <label htmlFor='phone_number' className='text-[13px] font-bold'>
                  電話番号
                </label>
                <input
                  type='string'
                  className='border-grey-light block w-full rounded border p-3'
                  {...register("phone_number")}
                />
                <span className='absolute p-1 text-xs text-red-500'>{errors.phone_number?.message}</span>
              </div>
            </div>
            <p className='mb-8 text-[12px] text-gray-500'>半角数字、ハイフンなしで入力してください</p>
            <p className='mb-8 text-[14px]'>
              SMSの受信拒否設定がされていないことをご確認のうえ、以下のボタンを押してください。
            </p>

            <button
              type='submit'
              className='my-1 w-full rounded-full bg-blue-700 py-3 text-center text-[13px] font-bold text-white hover:bg-blue-500 focus:outline-none'
              onClick={handleSubmit(onSubmit)}
            >
              認証コードを受け取る
            </button>
            <div className='text-grey-dark mb-8 mt-6 text-center text-[14px] text-blue-800'>
              <a className='no-underline' href='/login'>
                ログインはこちら
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegisterPage;
