import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Loading from "react-loading";
import { useRecoilState } from "recoil";

import ProgressBar from "@/components/layouts/common/ProgressBar";
import useAuth from "@/hooks/useAuth";
import useControlBlowserBack from "@/hooks/useControlBlowserBack";
import { locationState } from "@/stores/atoms/locationAtom";

function LegalPage() {
  useControlBlowserBack();
  const { isReady } = useAuth();
  const navigate = useNavigate();
  const [, setKey] = useRecoilState(locationState);
  const [isAgree, setIsAgree] = useState(false);

  const handleChangeIsAgree = (check: boolean) => {
    setIsAgree(check);
  };

  const handleAgree = () => {
    setKey((prevValue) => ({ ...prevValue, path: "/register" }));
    navigate("/register");
  };

  useEffect(() => {
    if (!isReady) setKey((prevValue) => ({ ...prevValue, isLoading: true }));
    else setKey((prevValue) => ({ ...prevValue, isLoading: false }));
  }, [isReady]);

  return (
    <div className='flex min-h-screen flex-col bg-blue-100 font-sans'>
      {isReady ? (
        <div className='container mx-auto flex max-w-md flex-1 flex-col items-center justify-center px-2'>
          <div className='text-black absolute flex justify-center rounded bg-white px-6 py-8 sm:top-[88px] sm:h-[450px] sm:w-[375px] md:top-[111px] md:h-[450px] md:w-[760px]'>
            <ProgressBar progress={0} />
            <div className='absolute w-[450px] sm:top-[110px] sm:max-w-[320px] md:top-[90px] md:max-w-[450px]'>
              <p className='text-[16px] font-bold'>利用規約</p>
              <a
                className='border-b border-gray-500 text-[14px] text-gray-600 hover:border-gray-300 hover:text-gray-300'
                href='/term'
                target='_blank'
                rel='noopener noreferrer'
              >
                {"https://kasooku.jp/term"}
              </a>
              <p className='mt-4 text-[16px] font-bold'>プライバシーポリシー</p>
              <a
                className='border-b border-gray-500 text-[14px] text-gray-600 hover:border-gray-300 hover:text-gray-300'
                href='https://termhub.jp/view/term.html?k=O0EXm%2FMIpuQdH1LDZN1AxRVQAiIss7O1cgis7IVOfDc%3D'
                target='_blank'
                rel='noopener noreferrer'
              >
                {"https://termhub.jp/view/term.html?k=O0EXm%2FMIpuQdH1LDZN1AxRVQAiIss7O1cgis7IVOfDc%3D"}
              </a>
              <div className='mt-8 flex'>
                <input
                  type='checkbox'
                  className='mr-2 mt-2 h-5 w-5 rounded-md border border-gray-200 md:mt-0'
                  onChange={(e) => handleChangeIsAgree(e.target.checked)}
                />
                <p className='text-[14px] md:mt-[1px]'>上記の「利用規約」「プライバシーポリシー」に同意する</p>
              </div>
              <div className='mt-8 flex w-full justify-center'>
                <button
                  type='submit'
                  className={`my-1 h-[40px] w-[220px] rounded-[4px] py-2 text-center font-bold leading-5 text-white 
              focus:outline-none ${isAgree ? "bg-blue-700 hover:bg-blue-500" : "bg-gray-400"}`}
                  disabled={!isAgree}
                  onClick={handleAgree}
                >
                  同意して登録を開始する
                </button>
              </div>
              <div className='my-2 flex w-full justify-center text-[14px]'>登録時間の目安： ３分間</div>
            </div>
          </div>
        </div>
      ) : (
        <div className='min-h-screen'>
          <Loading />
        </div>
      )}
    </div>
  );
}

export default LegalPage;
